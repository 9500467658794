import { useContext } from 'react'

import { createClickedSegmentEvent } from 'Clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents'

import { NavigationContext } from 'Contexts/Navigation/NavigationContext'

import Button from 'Clutch/Molecules/Button'

import Panel from 'Clutch/Atoms/Panel'
import Svg from 'Clutch/Atoms/Svg'
import Typography from 'Clutch/Atoms/Typography'

import styles from './MobileYmmSelector.module.scss'

const MobileYmmSelector = ({ suppressOtherFitment, audience }) => {
  const { dispatch: navigationDispatch } = useContext(NavigationContext)

  return (
    <div className={styles.wrapper}>
      {((suppressOtherFitment && audience === 'street') ||
        !suppressOtherFitment) && (
        <>
          <Button
            intent={'subdued'}
            segmentEvent={createClickedSegmentEvent(
              'HeroSectionAddYMM:TreatmentB',
            )}
            size={'small'}
            fill
            onClick={() => {
              navigationDispatch({
                type: 'setCustomerProjectDrawerState',
                payload: {
                  garageState: {
                    isCustomerProjectDrawerOpen: true,
                    customerProjectDrawerView: 'ADD_YMM',
                  },
                },
              })
            }}
          >
            <Panel transparent inverseTheme className={styles.panel}>
              <div className={styles.buttonContent}>
                <div className={styles.buttonContent}>
                  <Svg icon={'car'} size={1} intent={'action'} />
                  <Typography font={'bold'}>Add Year/Make/Model</Typography>
                </div>
                <Svg icon={'chevron'} size={1.25} intent={'action'} />
              </div>
            </Panel>
          </Button>
        </>
      )}
      {((suppressOtherFitment && audience === 'race') ||
        !suppressOtherFitment) && (
        <>
          <Button
            intent={'subdued'}
            segmentEvent={createClickedSegmentEvent(
              'HeroSectionAddRT:TreatmentB',
            )}
            size={'small'}
            onClick={() => {
              navigationDispatch({
                type: 'setCustomerProjectDrawerState',
                payload: {
                  garageState: {
                    isCustomerProjectDrawerOpen: true,
                    customerProjectDrawerView: 'ADD_RACETYPE',
                  },
                },
              })
            }}
          >
            <Panel transparent inverseTheme className={styles.panel}>
              <div className={styles.buttonContent}>
                <div className={styles.buttonContent}>
                  <Svg icon={'flag-checkered'} size={1} intent={'action'} />
                  <Typography font={'bold'}>Add a Race Car</Typography>
                </div>
                <Svg icon={'chevron'} size={1.25} intent={'action'} />
              </div>
            </Panel>
          </Button>
        </>
      )}
      {!suppressOtherFitment && (
        <>
          <Button
            intent={'subdued'}
            segmentEvent={createClickedSegmentEvent(
              'HeroSectionAddEngine:TreatmentB',
            )}
            size={'small'}
            onClick={() => {
              console.log('New Engine Interaction')
              navigationDispatch({
                type: 'setCustomerProjectDrawerState',
                payload: {
                  garageState: {
                    isCustomerProjectDrawerOpen: true,
                    customerProjectDrawerView: 'ADD_ENGINE',
                  },
                },
              })
            }}
          >
            <Panel transparent inverseTheme className={styles.panel}>
              <div className={styles.buttonContent}>
                <div className={styles.buttonContent}>
                  <Svg icon={'v8-engine'} size={1} intent={'action'} />
                  <Typography font={'bold'}>Add an Engine</Typography>
                </div>
                <Svg icon={'chevron'} size={1.25} intent={'action'} />
              </div>
            </Panel>
          </Button>
        </>
      )}
    </div>
  )
}

export default MobileYmmSelector
