function getVehiclePlaceholder(vehicle, audience) {
  let placeholder = ''
  const def = `Select ${audience === 'race' ? 'Your Race Car' : 'Your Vehicle'}`
  if (!vehicle) return def

  if (vehicle?.raceTypeId) {
    placeholder = vehicle.raceDisplay ?? vehicle.raceName
  } else if (vehicle?.yearId || vehicle?.year) {
    placeholder = `${vehicle?.yearId ?? vehicle?.year} ${
      vehicle?.makeDisplay ?? vehicle?.make
    } ${vehicle?.modelDisplay ?? vehicle?.model}`
  } else if (vehicle.engineDisplayName) {
    placeholder = vehicle.engineDisplayName
  }

  if (placeholder.includes('null') || placeholder.includes('undefined')) {
    return def
  }
  return `Shop for your ${placeholder}`
}

export { getVehiclePlaceholder }
