const modularSectionTypes = {
  ugc_black: 'UGC_BLACK',
  ugc_white: 'UGC_WHITE',
  emailNewsletter_black: 'EMAIL_NEWSLETTER_BLACK',
  emailNewsletter_purple: 'EMAIL_NEWSLETTER_PURPLE',
  recProductBlock: 'REC_PRODUCT_BLOCK',
  mrpEvents: 'MRP_EVENTS',
  toolbox: 'TOOLBOX',
  categories: 'CATEGORIES',
  flatCategories: 'FLAT_CATEGORIES',
  streetRaceCallouts: 'STREET_RACE_CALLOUTS',
  engineShockCallouts: 'ENGINE_SHOCK_CALLOUTS',
  brands: 'BRANDS',
  shopLinks: 'SHOP_LINKS',
  catalogGiftcardCallouts: 'CATALOG_GIFTCARD_CALLOUTS',
  valueAdds: 'VALUE_ADDS',
  valueAddsWhiteSmall: 'VALUE_ADDS_WHITE_SMALL',
  masonry: 'MASONRY',
  personalized: 'PERSONALIZED',
  pageContent: 'PAGE_CONTENT',
  maker: 'MAKERCONTENT',
  topVehicles: 'TOPVEHICLES',
  makerBoosted: 'MAKER_BOOSTED',
  makerBurried: 'MAKER_BURRIED',
  hero: 'GARAGE',
  hero_nogarage: 'HERO_NOGARAGE',
  garage_small: 'GARAGE_SMALL',
  marketLinks: 'MARKETLINKS',
  raceTypeLinks: 'RACETYPELINKS',
  sanctioningBodies: 'SANCTIONINGBODIES',
  topCategories: 'TOP_CATEGORIES',
}

export default modularSectionTypes
