import React, { useContext, useEffect, useRef, useState } from 'react'

import { IsUserBot } from '@speedwaymotors/clutch/Utilities/CookieUtilties/UserCookieUtilities'
import { useThemeStore } from 'Clutch/Stores/ThemeStore'
import { useVehicleStore } from 'Clutch/Stores/VehicleStore'
import ClassNames from 'classnames'

import { createClickedSegmentEvent } from 'Clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents'
import getEngineIds from 'Utilities/CustomerProjectUtilities/getEngineIds'

import { NavigationContext } from 'Contexts/Navigation/NavigationContext'
import { getProperUrl } from 'Contexts/VehicleContext/CoreVehicleRequests'

import useSegment from 'Clutch/Hooks/useSegment/useSegment'

import Button from 'Molecules/Button'

import AutoComplete from 'Clutch/Atoms/Form/Elements/AutoComplete/AutoComplete'
import Typography from 'Clutch/Atoms/Typography'

import { useFitmentStateContext } from '../../../../../providers/FitmentStateProvider'
import styles from '../YmmSelector.module.scss'

const initialDropdownState = {
  raceType: {
    value: null,
    label: null,
  },
  stockEngine: {
    value: null,
    label: null,
  },
}

const RaceTypeTab = ({ isEnabled }) => {
  const [errorState, setErrorState] = useState(false)
  const [dropdownState, setDropdownState] = useState(initialDropdownState)

  const raceTypes = useVehicleStore((x) => x.context.raceTypes)
  const stockEngines = useVehicleStore((x) => x.context.stockEngines)
  const options = []

  const addCustomerProject = useVehicleStore((x) => x.addCustomerProject)
  const fetchRaceTypeData = useVehicleStore((x) => x.fetchRaceTypeData)
  const setIsLoading = useThemeStore((state) => state.setIsLoading)
  const reloadCurrentLocation = useVehicleStore(
    (x) => x.context.reloadCurrentLocation,
  )
  const selectCustomerProject = useVehicleStore((x) => x.selectCustomerProject)
  const setExistingCustomerProjectState = useVehicleStore(
    (x) => x.setExistingCustomerProjectState,
  )

  const { sendCustomSegmentTrackEvent } = useSegment()
  const { state: navigationState, dispatch: navigationDispatch } =
    useContext(NavigationContext)
  const { garage } = navigationState
  const { isCustomerProjectDrawerOpen } = garage
  const { isFindPartsClicked, setIsFindPartsClicked } = useFitmentStateContext()

  const raceRef = useRef()
  const stockEngineRef = useRef()

  raceTypes?.forEach((raceType) => {
    options.push({
      label: raceType?.displayName,
      value: raceType?.raceTypeId,
    })
  })

  useEffect(() => {
    if (isFindPartsClicked && isEnabled) {
      setIsFindPartsClicked(false)
      handleButtonClick()
    }
  }, [isFindPartsClicked, isEnabled])

  useEffect(() => {
    const { raceType, stockEngine } = dropdownState

    if (IsUserBot()) return false

    if (raceType.value && stockEngine.value && stockEngine.value !== -1) {
      addRaceType()
    } else {
      fetchRaceTypeData(raceType.value)
    }
  }, [dropdownState])

  const addRaceType = async () => {
    setIsLoading(true)
    const raceTypeId = dropdownState.raceType.value
    try {
      const parsedRaceTypeId = parseInt(raceTypeId, 10)
      const raceType = raceTypes.find(
        (raceType) => raceType.optionId === parsedRaceTypeId,
      )
      const engineDefinitionId = dropdownState.stockEngine.value
      const selectedStockEngine = stockEngines?.find(
        (x) => x.optionId === engineDefinitionId,
      )

      const engineIds = getEngineIds(false, false, selectedStockEngine)

      const response = await addCustomerProject({
        raceTypeId: parsedRaceTypeId,
        raceTypeDisplayName: raceType.displayName,
        engineIds,
      })
      const addedProjectId = response.addedCustomerProjectId

      if (!response) {
        setIsLoading(false)
        return
      }

      const selectedProject = response.projects.find(
        (project) => project.projectId === addedProjectId,
      )
      const url = getProperUrl(selectedProject)

      await selectCustomerProject(selectedProject.projectId, url)

      if (reloadCurrentLocation) {
        // return false or it skips to line below
        window.location.reload()
        return false
      }
      window.location.href = url
    } catch (ex) {
      console.error(ex)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (dropdownState.raceType?.value && errorState) {
      setErrorState(false)
    }
  }, [errorState, dropdownState])

  useEffect(() => {
    if (dropdownState.stockEngine.value === -1) {
      const event = createClickedSegmentEvent('Engine Swap Option')
      sendCustomSegmentTrackEvent(event)
      setExistingCustomerProjectState(dropdownState)
      navigationDispatch({
        type: 'setCustomerProjectDrawerState',
        payload: {
          garageState: {
            isCustomerProjectDrawerOpen: true,
            customerProjectDrawerView: 'ADD_RACETYPE',
          },
        },
      })
    }
  }, [dropdownState.stockEngine.value === -1])

  const handleButtonClick = () => {
    if (
      !dropdownState.raceType?.value &&
      raceRef?.current &&
      !isCustomerProjectDrawerOpen
    ) {
      raceRef.current.focus()
      setErrorState(true)
    } else if (dropdownState.raceType?.value && !isCustomerProjectDrawerOpen) {
      addRaceType()
    }
  }

  return (
    <div className={styles.modalPanel}>
      <div className={styles.container}>
        <RtDropdown
          dropdownData={raceTypes}
          onClick={handleButtonClick}
          onChange={(option) => {
            setDropdownState(() => ({
              ...initialDropdownState,
              raceType: {
                value: parseInt(option.value, 10),
                label: option.label,
              },
            }))
          }}
          placeholder={'Select Your Race Car'}
          selectedValue={dropdownState.raceType}
          label={'Race Car'}
          isErrorState={errorState}
          selectRef={raceRef}
        />
        <RtDropdown
          dropdownData={dropdownState.raceType?.value && stockEngines}
          onClick={handleButtonClick}
          onChange={(option) => {
            console.log('New Engine Interaction')
            setDropdownState((prevState) => ({
              ...prevState,
              stockEngine: {
                value: parseInt(option.value, 10),
                label: option.label,
              },
            }))
          }}
          label={'Engine (Optional)'}
          selectedValue={dropdownState.stockEngine}
          placeholder={'Select an Engine'}
          selectRef={stockEngineRef}
        />
        <Button
          className={styles.addRaceVehicleButton}
          brand={'secondary'}
          fill
          size={'small'}
          type={'button'}
          onClick={handleButtonClick}
          testingName={'findPartsRace'}
          segmentEvent={createClickedSegmentEvent('AddRaceVehicleHomePage')}
        >
          <Typography
            className={styles.buttonText}
            tone={'contrast'}
            size={0.75}
            font={'bold'}
            inline
          >
            Find Parts
          </Typography>
        </Button>
      </div>
    </div>
  )
}

const RtDropdown = ({
  label,
  placeholder,
  selectedValue,
  dropdownData,
  onClick,
  onChange,
  selectRef,
  isErrorState,
}) => {
  const options = []

  let emptyStyling = ''

  if (dropdownData?.length > 0) {
    dropdownData.forEach((data) => {
      options.push({
        label: data.displayName,
        value: data.optionId,
      })
    })
  } else {
    emptyStyling = styles.streetEmptyDropdown
  }

  var isDisabled = !dropdownData || dropdownData.length === 0

  return (
    <div
      className={`${ClassNames(styles.rtDropdownItem, emptyStyling)} ${
        isDisabled ? '' : `lr-${label}Selector`
      }`}
      onClick={isDisabled ? onClick : () => {}}
    >
      <AutoComplete
        options={options}
        onChange={(value) => onChange(value)}
        label={label}
        selectedValue={selectedValue}
        isDisabled={isDisabled}
        placeholder={placeholder}
        ref={selectRef}
        isErrorState={isErrorState}
        useNativeSelect={false}
      />
    </div>
  )
}

export default RaceTypeTab
