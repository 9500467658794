import { createContext, useContext, useState } from 'react'

import { RecommendationProvider } from 'Stores/RecommendationStore/RecommendationStore'

const HomePageContext = createContext()

const HomePageProvider = ({
  intialState,
  freeShippingThreshold,
  children,
  pageRecSections = ['HomePage'],
}) => {
  const [context] = useState({ ...intialState, freeShippingThreshold })

  return (
    <HomePageContext.Provider value={context}>
      <RecommendationProvider sections={pageRecSections} isPageLoading={false}>
        {children}
      </RecommendationProvider>
    </HomePageContext.Provider>
  )
}

const useHomePage = () => {
  const context = useContext(HomePageContext)
  return context
}

export { HomePageProvider, useHomePage }
