import { createContext, useContext, useEffect, useState } from 'react'

import { useVehicleStore } from 'Clutch/Stores/VehicleStore'

import { useHomePage } from './HomePageProvider'

const fitmentStates = {
  vehicleSelected: 'VEHICLE_SELECTED',
  noVehicleSelected: 'NO_VEHICLE_SELECTED',
  noVehiclesInGarage: 'NO_VEHICLES_IN_GARAGE',
}

const FitmentStateContext = createContext()

const FitmentStateProvider = ({ children }) => {
  const setCurrentTab = useVehicleStore((x) => x.setCurrentTab)
  const hydrated = useVehicleStore((x) => x.context.hydrated)
  const customerProjects = useVehicleStore((x) => x.context.customerProjects)
  const selectedCustomerProjectState = useVehicleStore(
    (x) => x.context.selectedCustomerProject,
  )
  const { hasCustomerProjects } = useHomePage()
  const [fitmentState, setFitmentStates] = useState(
    hasCustomerProjects
      ? fitmentStates.noVehicleSelected
      : fitmentStates.noVehiclesInGarage,
  )
  const [selectedCustomerProject, setSelectedCustomerProject] = useState(null)
  const [isFindPartsClicked, setIsFindPartsClicked] = useState(false)

  useEffect(() => {
    if (hydrated) {
      if (customerProjects?.length > 0) {
        if (selectedCustomerProjectState?.projectId) {
          setSelectedCustomerProject(selectedCustomerProjectState)
          setFitmentStates(fitmentStates.vehicleSelected)
        } else {
          setFitmentStates(fitmentStates.noVehicleSelected)
        }
      } else {
        setFitmentStates(fitmentStates.noVehiclesInGarage)
      }
    }
  }, [
    hydrated,
    customerProjects,
    selectedCustomerProjectState,
    setSelectedCustomerProject,
    setFitmentStates,
  ])

  return (
    <FitmentStateContext.Provider
      value={{
        fitmentState,
        selectedCustomerProject,
        isFindPartsClicked,
        setIsFindPartsClicked,
        setCurrentTabForAudience: (audience) => {
          setCurrentTab(audience === 'race' ? audience : 'street')
        },
      }}
    >
      {children}
    </FitmentStateContext.Provider>
  )
}

const useFitmentStateContext = () => {
  const context = useContext(FitmentStateContext)
  return context
}

export { FitmentStateProvider, useFitmentStateContext, fitmentStates }
