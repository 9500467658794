import React, { useEffect, useRef } from 'react'

import { useThemeStore } from 'Clutch/Stores/ThemeStore'
import { useVehicleStore } from 'Clutch/Stores/VehicleStore'
import ClassNames from 'classnames'

import { createClickedSegmentEvent } from 'Clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents'

import { getProperUrl } from 'Contexts/VehicleContext/CoreVehicleRequests'

import Button from 'Clutch/Molecules/Button'

import AutoComplete from 'Clutch/Atoms/Form/Elements/AutoComplete/AutoComplete'
import Typography from 'Clutch/Atoms/Typography'

import AppName from '../../../../../appName/appName'
import { useFitmentStateContext } from '../../../../../providers/FitmentStateProvider'
import styles from '../YmmSelector.module.scss'
import { getVehiclePlaceholder } from './tabContentUtils'

const GarageTab = ({ isEnabled }) => {
  const customerProjects = useVehicleStore((x) => x.context.customerProjects)
  const selectedCustomerProject = useVehicleStore(
    (x) => x.context.selectedCustomerProject,
  )
  const options = formatGarageDataForSelect(customerProjects)
  const placeholder = getVehiclePlaceholder(selectedCustomerProject)

  const selectCustomerProject = useVehicleStore((x) => x.selectCustomerProject)
  const setIsLoading = useThemeStore((state) => state.setIsLoading)
  const { isFindPartsClicked, setIsFindPartsClicked } = useFitmentStateContext()

  const dropdownStyles = `${ClassNames(
    styles.garageDropdown,
    options.length === 0 ? styles.streetEmptyDropdown : null,
  )} lr-GarageSelector`

  const dropdownRef = useRef()

  useEffect(() => {
    if (isFindPartsClicked && isEnabled) {
      setIsFindPartsClicked(false)
      openDropdown()
    }
  }, [isFindPartsClicked, isEnabled])

  const openDropdown = () => {
    if (!selectedCustomerProject?.projectId && dropdownRef.current)
      dropdownRef.current.focus()
  }

  const onVehicleChange = async (option) => {
    setIsLoading(true)
    const project = customerProjects?.find((x) => x.projectId === option.value)
    const url = getProperUrl(project)
    await selectCustomerProject(project.projectId, url)
  }

  return (
    <div className={styles.container}>
      <div className={dropdownStyles}>
        <AutoComplete
          options={options}
          onChange={onVehicleChange}
          label={'Garage'}
          displayLabel={false}
          selectedValue={selectCustomerProject?.projectId}
          isDisabled={options.length === 0}
          placeholder={placeholder}
          placeholderStyles={{ maxWidth: '250px' }}
          ref={dropdownRef}
          useNativeSelect={false}
        />
      </div>
      <div className={ClassNames(styles.ymmModalItem, styles.garageButton)}>
        <Button
          brand={'secondary'}
          size={'small'}
          fill
          href={
            selectedCustomerProject?.shopUrl ??
            selectedCustomerProject?.customerProjectShopUrl
          }
          type={'button'}
          className={'lr-findPartsDesktop'}
          testingName={'findPartsGarage'}
          segmentEvent={createClickedSegmentEvent(
            `${AppName}_Find_Parts_Button`,
          )}
          onClick={() => {
            openDropdown()
          }}
        >
          <Typography
            className={styles.buttonText}
            tone={'contrast'}
            size={0.75}
            font={'bold'}
            inline
          >
            Find Parts
          </Typography>
        </Button>
      </div>
    </div>
  )
}

const formatGarageDataForSelect = (garageData) => {
  const options = []
  if (garageData?.length > 0) {
    garageData
      .filter(
        (x) =>
          x.raceName ||
          x.raceDisplay ||
          x.year ||
          x.yearId ||
          x.engineDisplayName,
      )
      .forEach((vehicle) => {
        const race = vehicle.raceName ?? vehicle.raceDisplay
        const ymm =
          vehicle.year || vehicle.yearId
            ? `${vehicle.year ?? vehicle.yearId} ${
                vehicle.make ?? vehicle.makeDisplay
              } ${vehicle.model ?? vehicle.modelDisplay}`
            : undefined
        const engine = vehicle.engineDisplayName
        options.push({
          label: race ?? ymm ?? engine,
          value: vehicle.projectId,
        })
      })
  }

  return options
}

export default GarageTab
